<template>
    <div class="cs_content">
        <div class="two_depth">
            <!-- <span class="two_depth_btn @click="getFaqfilter()">전체</span> -->
            <select name="" id=""></select>
            <span class="two_depth_btn on" @click="addClass('Howtouse')">이용방법</span>
            <span class="two_depth_btn" @click="addClass('Payment')">구독 및 결제</span>
            <!-- <span class="two_depth_btn" @click="addClass('Unsubscribe')">구독 해지</span> -->
            <span class="two_depth_btn" @click="addClass('Profile')">프로필관리</span>
            <span class="two_depth_btn" @click="addClass('Etc')">기타</span>
        </div>
        <div class="cs_list_box" @mouseover.once="toggleOnOff()">
            <template v-for="(item, index) in FaqList">
                <ul class="cs_list" style="margin-bottom:0px; border-radius: 2px;" :key="index">
                    <li style="padding:20px 0;">
                        <p style="margin-bottom:0px;">{{ item.faqTitle }}</p>
                        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 7.99935L0 0.666016H12L6 7.99935Z" fill="white" fill-opacity="0.87"/>
                        </svg>
                    </li>
                    <li class="sub_cs_list">
                        <span>A.</span>
                        <p v-html="item.faqContent" style="margin-bottom:0px;"></p>
                    </li>
                </ul>
            </template>
        </div>
        <infinite-loading @infinite="infiniteHandler" :identifier="infiniteId" spinner="waveDots">
            <div slot="no-more"></div>
        </infinite-loading>
    </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import InfiniteLoading from 'vue-infinite-loading';
import $ from "jquery";
import axios from "axios";
import VueCookies from "vue-cookies";

export default defineComponent({
  components: {
    InfiniteLoading
  },

  data: function () {
    return {
      // 리스트
      FaqList: [],
      faqCategory: 'Howtouse',
      FaqListData: [],
      infiniteId: new Date(),

      // 무한 스크롤
      limit: 0,
      total: 0
    };
  },

  updated () {
    this.set();
  },

  methods: {
    set () {
      $(".sub_cs_list").hide();
    },

    toggleOnOff () {
      $(document).off("click").on("click", ".cs_list > li:first-child", function () {
        if ($(this).hasClass('status') === true) {
          $('.status').removeClass('status');
          $(this).siblings().slideToggle(300);
          $(this).children("svg").toggleClass("rotate");
        } else {
          $('.status').siblings().slideUp(300);
          $('.status').removeClass('status');
          $(this).addClass('status');
          $(this).siblings().slideToggle(300);
          $(this).children("svg").toggleClass("rotate");
        }
      });
    },

    addClass (category) {
      $(document).on('click', '.two_depth span', function () {
        $(".two_depth").find('span').removeClass('on');
        $(this).addClass('on');
      });
      this.faqCategory = category;
      this.limit = 0;
      this.FaqList = [];
      this.FaqListData = [];
      this.infiniteId += 1;
    },

    changeType () {
      this.limit = 0;
      this.FaqListData = [];
      this.FaqList = [];
      this.infiniteId += 1;
    },

    infiniteHandler ($state) {
      var faqCategory = this.faqCategory;
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };
      var limit = this.limit;

      axios
        .post('/api/menu/getFaqList', { faqCategory, limit }, { headers })
        .then(res => {
          if (res.data.result.length === 0) {
            $state.complete();
          } else {
            setTimeout(() => {
              var data = res.data.result;
              for (var i in data) {
                data[i].faqContent = data[i].faqContent.replaceAll('&lt;', '<').replaceAll('&gt;', '>').replaceAll("&amp;", "&").replaceAll("&#x2F;", "/").replace(/(?:\r\n|\r|\n)/g, "</br>");
                this.FaqListData.push(data[i]);
              }
              // console.log(this.FaqListData);
              this.limit += 10;
              this.getFaqfilter(faqCategory);
              if (data.length < 10) {
                $state.complete();
              }
              $state.loaded();
            }, 1000);
          }
        })
        .catch(err => {
          $state.complete();
          console.log(err);
        });
    },

    /*
    async getFaqList () {
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };
      var faqCategory = "";

      await axios
        .post("/api/menu/getFaqList", { faqCategory }, { headers })
        .then(res => {
          //this.FaqList = res.data.result;
          this.FaqListData = res.data.result;
          //this.total = res.data.result.length;
          this.getFaqfilter('Howtouse');
        });
      this.set();
    },
    */
    getFaqfilter (category) {
      this.FaqList = this.FaqListData.filter((faq) => {
        return (faq.faqCategory.includes(category));
      });
    }
  }
});
</script>

<style scoped>
.cs .two_depth .two_depth_btn.on { color: #fff !important; font-weight: 500; background: rgba(255, 17, 80, 0.87) !important; border: none !important; }
</style>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
